import Cookies from 'js-cookie';
import { differenceInDays } from 'date-fns';

interface Store {
  setItem: (key: string, value: any) => void;
  getItem: <T = any>(key: string) => T | null;
}

export const localStore: Store = {
  setItem: (key: string, value: any) => {
    if (typeof window === 'undefined') return null;
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn('Could not access localStorage', e);
      return null;
    }
  },
  getItem: (key: string) => {
    if (typeof window === 'undefined') return null;
    try {
      const value = window.localStorage.getItem(key);
      if (value) return JSON.parse(value);
      return null;
    } catch (e) {
      console.warn('Could not access localStorage', e);
      return null;
    }
  },
};

export const sessionStore: Store = {
  setItem: (key: string, value: any) => {
    if (typeof window === 'undefined') return null;
    try {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn('Could not access sessionStorage', e);
      return null;
    }
  },
  getItem: (key: string) => {
    if (typeof window === 'undefined') return null;
    try {
      const value = window.sessionStorage.getItem(key);
      if (value) return JSON.parse(value);
      return null;
    } catch (e) {
      console.warn('Could not access sessionStorage', e);
      return null;
    }
  },
};

const cookieStore: Store = {
  setItem: (key: string, value: any) => Cookies.set(key, JSON.stringify(value)),
  getItem: (key: string) => {
    const value = Cookies.get(key);
    if (value) return JSON.parse(value);
    return null;
  },
};

export const storage =
  typeof window !== 'undefined' &&
  window?.localStorage?.getItem('dummyKey') !== undefined
    ? localStore
    : cookieStore;

export const shouldShowPopup = (storageKey: string) => {
  const dismissDate = storage.getItem(storageKey);

  const difference = differenceInDays(new Date(), new Date(dismissDate));

  if (!(dismissDate && difference < 1)) {
    return true;
  }

  return false;
};

// Used for highlighted search feature
export function setCookie(name: string, value: string, days: number) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name: string) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name: string) {
  document.cookie = name + '=; Max-Age=-99999999;';
}
import React, { Component } from 'react';
import { BlockContent } from 'components/BlockContent';
import cx from 'classnames';
import { Button, Img } from 'components/base';
import Language from 'constants/Language';
import { DonationAmount, PortableText } from 'sharedTypes';
import { shouldShowPopup, storage } from 'utils/storage';
import { curlyQuotes } from 'utils/text';
import { clickTrigger } from 'lib/GTM';

interface Props {
  title: string;
  description: PortableText;
  popupIsActive: boolean;
  link: string;
  amounts: DonationAmount[];
}

interface State {
  color: Color.BEIGE | Color.RED | Color.BLACK;
  layout: Layout.MULTI_BUTTON | Layout.ONE_BUTTON;
  popupIsVisible: boolean;
}

enum Layout {
  MULTI_BUTTON = 'multi-button',
  ONE_BUTTON = 'one-button',
}

enum Color {
  RED = 'red',
  BLACK = 'black',
  BEIGE = 'beige',
}

const DONATION_PAGE_URL = 'https://tabletmag.networkforgood.com/';
const DONATION_AMOUNTS = [
  { label: '$36', param: '36' },
  { label: '$50', param: '50' },
  { label: '$75', param: '75' },
  { label: '$125', param: '125' },
  { label: '$360', param: '360' },
  { label: 'Custom', param: 'custom' },
];

const donationPopupKey = 'donationPopUpDismissDate';

class DonationPopup extends Component<Props, State> {
  state: State = {
    color: Color.BEIGE,
    layout: Layout.MULTI_BUTTON,
    popupIsVisible: false,
  };

  async componentDidMount() {
    this.setPopup();
  }

  setPopup() {
    const showPopup = shouldShowPopup(donationPopupKey);
    if (showPopup) {
      setTimeout(() => this.setState({ popupIsVisible: true }), 1000);
    }
  }

  handleDonationButtonClick = (value: string) => {
    const { color, layout } = this.state;

    clickTrigger({
      event: 'Donation Button Click',
      event_label: `color: ${color}, Layout: ${layout}, value: ${value}`,
      event_category: 'donation_popup_color_design_summer_2020',
      event_value: value,
    });

    this.dismissPopup();
  };

  dismissPopup = () => {
    this.setState({ popupIsVisible: false });
    this.setPopupCloseDate();
  };

  setPopupCloseDate = () => {
    storage.setItem(donationPopupKey, new Date());
  };

  render() {
    const { title, description, popupIsActive, link, amounts } = this.props;
    const { color, layout, popupIsVisible } = this.state;

    const CUSTOM_AMOUNTS = amounts.length !== 0 ? amounts : DONATION_AMOUNTS;

    if (!popupIsActive) return null;

    return (
      <div
        className={cx(
          `DonationPopup DonationPopup--${layout} transition-long w100 fixed b0 l0 r0 bg-color-${color} no-display-in-print`,
          {
            'color-off-white': color !== Color.BEIGE,
            'color-black': color === Color.BEIGE,
            'DonationPopup--inactive opacity-0': !popupIsVisible,
          }
        )}
      >
        <div
          className={cx('DonationPopup__container relative flex', {
            'flex-col justify-center items-center lg:flex-row lg:justify-between lg:items-start':
              layout === Layout.ONE_BUTTON,
            'flex-col items-center': layout === Layout.MULTI_BUTTON,
          })}
        >
          <div
            className={cx('DonationPopup__info-container text-center', {
              'lg:text-left': layout === Layout.ONE_BUTTON,
            })}
          >
            <div className="DonationPopup__title">
              <span className="itc-cushing">{title}</span>
            </div>
            <div
              className={cx(
                'DonationPopup__description DonationPopup__info-text graebenbach font-400',
                {
                  'DonationPopup__description--off-white':
                    color !== Color.BEIGE,
                }
              )}
            >
              <BlockContent blocks={description} />
            </div>
          </div>
          {layout === Layout.ONE_BUTTON && (
            <div className="flex flex-col">
              <Button
                ariaLabel={Language.t('DonationPopup.supportButtonLabel')}
                to={link ?? DONATION_PAGE_URL}
                onClick={() =>
                  this.handleDonationButtonClick('Open Donation Page')
                }
                containerClassName="DonationPopup__support-button-container"
                className={cx(
                  'DonationPopup__support-button font-700 graebenbach',
                  {
                    'bg-color-red color-off-white': color !== Color.RED,
                    'bg-color-off-white color-red': color === Color.RED,
                  }
                )}
              >
                <span>{Language.t('DonationPopup.supportButtonLabel')}</span>
                <span className="DonationPopup__support-button-arrow absolute transition-shorter ml_25">
                  &rarr;&#xFE0E;
                </span>
              </Button>
              <Button
                ariaLabel={Language.t('DonationPopup.dismissButtonAriaLabel')}
                onClick={this.dismissPopup}
                className={cx(
                  'DonationPopup__dismiss-text-button bg-color-transparent graebenbach font-400',
                  {
                    'color-gray-darker': color === Color.BEIGE,
                    'color-off-white': color === Color.RED,
                    'color-gray-lighter': color === Color.BLACK,
                  }
                )}
              >
                <span
                  className={cx('DonationPopup__dismiss-text-button-text', {
                    'DonationPopup__dismiss-text-button-text--gray-darker':
                      color === Color.BEIGE,
                    'DonationPopup__dismiss-text-button-text--off-white':
                      color === Color.RED,
                    'DonationPopup__dismiss-text-button-text--gray-lighter':
                      color === Color.BLACK,
                  })}
                >
                  {Language.t('DonationPopup.dismissButtonLabel')}
                </span>
              </Button>
            </div>
          )}
          {layout === Layout.MULTI_BUTTON && (
            <>
              <Button
                ariaLabel={Language.t('DonationPopup.dismissButtonAriaLabel')}
                onClick={this.dismissPopup}
                className="DonationPopup__close-icon bg-color-transparent absolute t0 r0"
              >
                <Img
                  alt={Language.t('DonationPopup.dismissButtonAriaLabel')}
                  src={`/assets/images/close-icon${
                    color !== Color.BEIGE ? '-white' : ''
                  }.svg`}
                  className="w100"
                />
              </Button>
              <div className="flex flex-col items-center">
                <div className="DonationPopup__donation-amount-button-container flex justify-center">
                  {CUSTOM_AMOUNTS.map((amount) => {
                    const amountIsCustom = amount.param === 'custom';
                    return (
                      <Button
                        key={amount.param}
                        ariaLabel={Language.t(
                          'DonationPopup.donateButtonLabel',
                          {
                            amount: amountIsCustom
                              ? 'custom amount'
                              : amount.label,
                          }
                        )}
                        to={`${link ?? DONATION_PAGE_URL}?donation_amount=${
                          amount.param
                        }`}
                        onClick={() =>
                          this.handleDonationButtonClick(
                            `Open Donate ${amount.label} Page`
                          )
                        }
                        className={cx(
                          'DonationPopup__donation-amount-button flex items-center justify-center',
                          {
                            'bg-color-red color-off-white': color !== Color.RED,
                            'bg-color-off-white color-red': color === Color.RED,
                            'DonationPopup__donation-amount-button--custom':
                              amountIsCustom,
                          }
                        )}
                      >
                        <span className="graebenbach font-700">
                          {curlyQuotes(amount.label)}
                        </span>
                      </Button>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default DonationPopup;
